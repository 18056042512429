<template>
  <div class="popup_sub_container">
    <h2>Manage Branch Stock</h2>
    <i class="closeme" @click="closeModal">X</i>
    <div class="jobs_table sp">
      <div class>
        <section>
          <div class="form_section1">
            <table>
              <tr>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Item Name
                    </label>
                    <input type="text" :value="item.postal_document_name" disabled />
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Document Type
                    </label>
                    <input type="text" :value="item.postal_document_type" disabled />
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="form_section1">
            <table>
              <tr>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Branch
                      <span>*</span>
                    </label>
                    <select v-model="stockItem.branch_id" @change="fetchBranchStockData">
                      <option value="" disabled>Select Branch</option>
                      <option v-for="(branch, i) in branches" :key="'branch-' + i" :value="branch.id">{{ branch.name }}
                      </option>
                    </select>
                  </div>
                </td>
                <td style="width:15rem;">
                  <div class="label_and_element_wrapper">
                    <label>
                      Current Stock On Hand
                    </label>
                    <input type="text" :value="current_stock" disabled />
                  </div>
                </td>
                <td style="width:15rem;">
                  <div class="label_and_element_wrapper">
                    <label>
                      Current Unit Price
                    </label>
                    <input type="text" :value="current_price" disabled />
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="methods">
            <label class="radio-inline">
              <input type="radio" id="stock_update" v-model="stockItem.method" value="stock_update">Stock Update
            </label>
            <label class="radio-inline">
              <input type="radio" id="change_price" v-model="stockItem.method" value="change_price">Change Price
            </label>
          </div>
          <div class="form_section1"v-if="stockItem.method == 'stock_update'">
            <table>
              <tr>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Purchased Date
                    </label>
                    <input type="date" class="" v-model="stockItem.purchase_date" />
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Total value
                    </label>
                    <input type="text" disabled :value="stockItem.purchase_value" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Unit price
                    </label>
                    <input type="text" v-model="stockItem.unit_price" @keydown="numberInput"
                      @change="calculatePurchaseValue" />
                  </div>
                </td>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      Quantity Purchased
                    </label>
                    <input type="text" v-model="stockItem.quantity" @keydown="numberInput"
                      @change="calculatePurchaseValue" />
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="form_section1"v-if="stockItem.method == 'change_price'">
            <table>
              <tr>
                <td>
                  <div class="label_and_element_wrapper">
                    <label>
                      New Unit price
                    </label>
                    <input type="text" v-model="stockItem.unit_price" @keydown="numberInput"
                      @change="calculatePurchaseValue" />
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </section>

        <section class="centered">
          <a href="javascript:void(0)" class="link_bt bt_cancel" @click="closeModal">CANCEL</a>
          <a href="javascript:void(0)" class="link_bt bt_save" @click="update">{{ stockItem.method ==  'stock_update' ?  'ADD QUANTITY' : 'UPDATE PRICE'  }}</a>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: ['item'],
  data() {
    return {
      stockItem: {
        postal_document_type_id: null,
        quantity: 0,
        unit_price: 0,
        purchase_value: 0,
        branch_id: '',
        purchase_date: moment(new Date()).format('YYYY-MM-DD'),
        method: 'stock_update',
      },
      branches: [],
      current_stock:0,
      current_price:0,
    }
  },
  created() {
    this.stockItem.postal_document_type_id = this.item.id;
    // this.stockItem.unit_price = this.item.unit_price

    this.fetchBranches();
  },
  watch: {
    'stockItem.method' : function(newValue, oldValue) {
      if(newValue == 'change_price') {
        this.stockItem.unit_price = '';
      }else {
        this.fetchBranchStockData()
      }
    }
  },
  methods: {
    calculatePurchaseValue() {
      const purchaseValue = this.stockItem.unit_price * this.stockItem.quantity
      this.stockItem.purchase_value = purchaseValue
    },
    closeModal() {
      this.$emit("closeModal");
    },
    numberInput(evt) {
      const input = evt
      const keyCode = input.keyCode

      const allowed = [
        8,  //backspace
        37, //left
        39, //right
      ]

      if (-1 === allowed.indexOf(keyCode) && !this.validate(input.key)) {
        evt.preventDefault()
      }

    },
    validate(s) {
      const rgx = /^[0-9]*\.?[0-9]*$/;
      return s.match(rgx);
    },
    fetchBranches() {
      this.axios
        .get("/api/branch/view")
        .then(response => {
          this.branches = response.data.branches;
        })
        .catch(error => {
          this.toast.error();
        });
    },
    async update() {

      if (!this.stockItem.branch_id || this.stockItem.branch_id == '') {

        this.toast.error('Please select branch');

        return;
      }

      if (!this.stockItem.method == 'change_price' || this.stockItem.unit_price == '') {

        this.toast.error('New unit price field can not be empty');

        return;
      }

      try {

        const data = this.stockItem;

        const response = await this.axios.put("/api/postaldocument/" + data.postal_document_type_id, data)

        this.toast.success(response.data.msg);

        this.closeModal();
      } catch (e) {
        console.log("error", e);
      }
    },
    fetchBranchStockData() {
      const data = this.item.branch_stocks.filter((branch) => {
         return branch.branch_id === this.stockItem.branch_id;
      });


      if(data.length > 0) {
        this.stockItem.unit_price = this.stockItem.method == 'stock_update' ? data[0].unit_price : '';
        this.current_stock = data[0].quantity;

        this.current_price = data[0].unit_price;
        return;
      }

      this.stockItem.unit_price = 0;
      this.current_stock = 0;
      this.current_price = 0;
    }
  }
}
</script>
<style lang="scss" scoped>
.popup_sub_container {
  padding: 20px;
  width: 100%;
  margin: auto;

  background: #fff;
}

.popup_sub_container>section {
  padding: 20px 0 0 0;
}

h3.sub_head {
  font-size: 20px;
  font-weight: 600;
  padding: 0;
}

.preferred_hide {
  display: none;
}

.chk_prefered {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin: 0 20px;
}

.chk_prefered:checked+hr+.preferred_hide {
  display: block;
}

.title_image {
  display: block;
  margin: auto;
}

.form_section1 {
  width: 100%;
  height: auto;
  padding: 20px;
  background: #ececec;
  margin: 20px 0;
}

i.closeme {
  width: 30px;
  height: 30px;
  background: #000;
  color: #06A5ED;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  border-radius: 100px;
  display: block;
  font-style: normal;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.jobs_table table {
  border: 0;
}

.jobs_table table tr td {
  padding: 0;
  border: 0;
}

.label_and_element_wrapper input[type="date"] {
  display: block;
  text-align: left;
  font-size: 14px;
  padding: 5px 20px;
  width: 100%;
  height: 40px;
  background: #fff;
  border: 1px solid #ccc;
}

.radio-inline {
    display: inline-block;
    margin-right: 10px;
    font-size: 18px;
    font-weight: 700;
}

.radio-inline input[type="radio"] {
    margin-right: 5px;
}
.methods {
  padding-block: 1rem;
  text-align: center;
}
</style>