<template>
	<div class="popup_sub_container">
		<h2>Stock History</h2>
		<i class="closeme" @click="closeModal">X</i>
    <div class="jobs_table sp">
      <div class>
					<section>
						<div class="form_section1">
              <table>
                <tr>
                  <td>
                      <div class="label_and_element_wrapper">
                        <label>
                          Item Name
                        </label>
                        <input type="text" :value="item.postal_document_name" disabled/>
                      </div>
                  </td>
                  <td>
                      <div class="label_and_element_wrapper">
                        <label>
                          Document Type
                        </label>
                        <input type="text" :value="item.postal_document_type" disabled/>
                      </div>
                  </td>
                  <td>
                    <div class="label_and_element_wrapper">
                      <label>
                        Current Total Stock on Hand
                      </label>
                      <input type="text" :value="totalCurrentStock" disabled/>
                    </div>
                  </td>
                  <td v-if="this.branch_id !== 'all'">
                    <div class="label_and_element_wrapper">
                      <label>
                        Current Unit Price
                      </label>
                      <input type="text" :value="currentUnitPrice" disabled/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="4">
                    <div class="label_and_element_wrapper">
                      <label>
                        Branch
                        <span>*</span>
                      </label>
                      <select v-model="branch_id" @change="fetchStockHistory">
                        <option value="all">All</option>
                        <option v-for="(branch, i) in branches" :key="'branch-' + i" :value="branch.id">{{ branch.name }}
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
						<div class="form_section1">
              <table class="table-history">
                <thead>
                  <tr>
                    <th>Purchased Date</th>
                    <th>Total Value</th>
                    <th>Unit Price</th>
                    <th>Quantity</th>
                    <th>Branch</th>
                    <th>MailRoom Staff</th>
                    <th>Date Encoded</th>
                  </tr>  
                </thead>
                <tbody>
                  <template v-if="stocks.length > 0">
                    <tr v-for="stock in stocks">
                      <td>{{stock.purchase_date | formatDate}}</td>
                      <td>$ {{stock.purchase_value.toFixed(2)}}</td>
                      <td>{{stock.unit_price}}</td>
                      <td>{{stock.quantity}}</td>
                      <td>{{stock.branch ? stock.branch.name : ''}}</td>
                      <td>{{stock.user ? stock.user.name : ''}}</td>
                      <td>{{stock.created_at | formatDate}}</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="6"><center>No Data</center></td>
                    </tr>
                  </template>
                </tbody>
              </table>
          </div>
          </section>
					<section class="centered">
						<a href="javascript:void(0)" class="link_bt bt_cancel" @click="closeModal">CLOSE</a>
					</section>
        </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  props: ['item'],
  data() {
    return {
      branches: [],
      stocks: [],
      branch_id:'all'
    }
  },
  filters: {
    formatDate(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).format('DD/MM/YYYY')
    },
  },
  computed: {
    totalCurrentStock() {
      return this.item.branch_stocks.filter((branch) => {

        if (this.branch_id !== 'all') {
          return branch.branch_id === this.branch_id;
        }

        return true;

      }).reduce((a, branch) => {
          return a + branch.quantity;
      }, 0);
    },
    currentUnitPrice() {
      const data = this.item.branch_stocks.filter((branch) => {
         return branch.branch_id === this.branch_id;
      });


      if(data.length > 0) {
        return data[0].unit_price;
      }

      return 0;
    }
  },
  created() {
    this.fetchBranches();
    this.fetchStockHistory();
  },
  methods: {
		closeModal() {
			this.$emit("closeModal");
		},
    fetchBranches() {
      this.axios
        .get("/api/branch/view")
        .then(response => {
          this.branches = response.data.branches;
        })
        .catch(error => {
          this.toast.error();
        });
    },
    fetchStockHistory() {
      this.stocks = this.item.stocks.filter((stock) => {
        
        if (this.branch_id !== 'all') {
          return stock.branch_id === this.branch_id;
        }

        return true;
      });

    }
  }
}
</script>
<style lang="scss" scoped>
.table-history {
  tbody {
    border-left:1px solid black;
    border-bottom:1px solid black;
    border-right:1px solid black;
    tr {
      td {
        border:1px solid black !important;
        padding:10px !important;
        font-weight: bold;
      }
    }
  }
}
.popup_sub_container {
	padding: 20px;
	width: 100%;
	margin: auto;

	background: #fff;
}

.popup_sub_container>section {
	padding: 20px 0 0 0;
}

h3.sub_head {
	font-size: 20px;
	font-weight: 600;
	padding: 0;
}

.preferred_hide {
	display: none;
}

.chk_prefered {
	width: 20px;
	height: 20px;
	display: inline-block;
	margin: 0 20px;
}

.chk_prefered:checked+hr+.preferred_hide {
	display: block;
}

.title_image {
	display: block;
	margin: auto;
}

.form_section1 {
	width: 100%;
	height: auto;
	padding: 20px;
	background: #ececec;
	margin: 20px 0;
}

i.closeme {
	width: 30px;
	height: 30px;
	background: #000;
	color: #06A5ED;
	font-size: 14px;
	text-align: center;
	line-height: 30px;
	border-radius: 100px;
	display: block;
	font-style: normal;
	font-weight: bold;
	position: absolute;
	right: 20px;
	top: 20px;
	cursor: pointer;
}
.jobs_table table {
	border: 0;
}

.jobs_table table tr td {
	padding: 0;
	border: 0;
}
.label_and_element_wrapper input[type="date"] {
  display: block;
  text-align: left;
  font-size: 14px;
  padding: 5px 20px;
  width: 100%;
  height: 40px;
  background: #fff;
  border: 1px solid #ccc;
}
</style>